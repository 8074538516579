import httpClient from "../httpClient"

import { formatData } from "../request"
import { encodeRequestParameters } from "../request"

import { accessFormat } from "../formats"

/**
 * Returns a list of residences
 */
export function getAccess(data) {
    data = formatData(accessFormat, data)
    if (data === false) {
        throw "500"
    }
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/acces", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * call OpenDoor to open an access
 */
export function openAccess(id) {
    return httpClient
        .post("/access/" + id + "/open", {})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Retrieve informations cylinder Or access. In intratone cylinder == access
 */
export function getCylinderAccess(id) {
    return httpClient
        .get("/acces/" + id + "/cylinder", {})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Post informations cylinder Or access. cylinder == access
 */
export function updateCylinderAccess({ id, params }) {
    return httpClient
        .post("/acces/" + id + "/cylinder", params)
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Delete cylinder Or access. cylinder == access
 */
export function deleteCylinderAccess({ id }) {
    return httpClient
        .delete("/acces/" + id + "/cylinder")
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
