<template>
    <!-- Display List -->
    <div class="access-list-individual" v-if="display === 'list'">
        <div
            v-if="
                isCreator === true &&
                checkRight('G_RESIDENCES') &&
                display === 'list'
            "
            class="list-creator"
            @click="createAction()"
        >
            <div>
                <i class="fas fa-plus"></i>
                <h1>{{ $t("accessCard.create") }}</h1>
            </div>
        </div>
        <div
            v-else-if="isConnectit === true && display === 'list'"
            class="access-list-connectit"
            @click="gotoConnectit()"
        >
            <div class="visual">
                <img
                    :src="imageBaseURL + 'Images/lora/connect_it_sm.png'"
                    alt="Connectit.it"
                />
            </div>
        </div>
        <div v-else-if="!isCreator" class="access-info">
            <div
                class="access-list-image"
                @click="gotoAccess(access.id, access.kibolt)"
            >
                <img :src="imageBaseURL + access.image" alt="" />
                <i
                    title="Connecté"
                    v-if="
                        access.kibolt === '1' ||
                        parseInt(access.centrale_id, 10) > 0
                    "
                    class="fas fa-check-circle intercom-check"
                ></i>
                <i
                    title="Non connecté"
                    v-else
                    class="fas fa-times-circle intercom-uncheck"
                ></i>
            </div>
            <div
                class="access-list-name"
                @click="gotoAccess(access.id, access.kibolt)"
            >
                {{ access.nom }}
            </div>

            <div
                class="options"
                v-if="checkRightEvent || checkRightCode || checkRightBadge"
            >
                <button
                    class="options-button dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <i class="fas fa-ellipsis-v"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="options-button">
                    <a
                        @click="goToV1View('data/events/evts/id=' + access.id)"
                        class="dropdown-item"
                        v-if="checkRightEvent"
                    >
                        <i class="fas fa-calendar-day"></i>
                        {{ $t("accessCard.events") }}
                    </a>
                    <a
                        v-if="checkRightCode"
                        @click="goToV1View('data/codes/codes/id=' + access.id)"
                        class="dropdown-item"
                    >
                        <i class="fas fa-keyboard"></i>
                        {{
                            $t("accessCard.codes", {
                                count: access.codes_count,
                            })
                        }}
                    </a>
                    <a
                        v-if="checkRightBadge"
                        @click="
                            goToV1View(
                                'data/search_all/srch_all/fromacces=' +
                                    access.id
                            )
                        "
                        class="dropdown-item"
                    >
                        <i class="fas fa-key"></i>
                        {{
                            $t("accessCard.badges", {
                                count: access.cles_count,
                            })
                        }}
                    </a>
                    <a
                        v-if="checkRightOpenDoor"
                        @click="callOpenDoor(access.id)"
                        class="dropdown-item"
                    >
                        <i class="fas fa-door-open"></i>
                        {{ $t("accessCard.opendoor") }}
                    </a>
                </ul>
            </div>
        </div>
    </div>

    <!-- Display Card -->
    <div class="access-card-individual" v-if="display === 'card'">
        <div class="card-creator-wrapper">
            <div
                v-if="
                    isCreator === true &&
                    checkRight('G_RESIDENCES') &&
                    display === 'card'
                "
                class="card-creator"
                @click="createAction()"
            >
                <i class="fas fa-plus"></i>
                <h1>{{ $t("accessCard.create") }}</h1>
            </div>
        </div>
        <div
            v-if="isConnectit === true && display === 'card'"
            class="card-connectit"
            @click="gotoConnectit()"
        >
            <div class="visual">
                <img
                    :src="imageBaseURL + 'Images/lora/connect_it_sm.png'"
                    alt="Connectit.it"
                />
            </div>
        </div>
        <div v-else-if="!isCreator === true" class="access-card-content">
            <div class="visual" @click="gotoAccess(access.id, access.kibolt)">
                <img
                    class="material"
                    :src="imageBaseURL + access.image"
                    :alt="access.nom"
                />
                <i
                    title="Connecté"
                    v-if="
                        access.kibolt === '1' ||
                        parseInt(access.centrale_id, 10) > 0
                    "
                    class="fas fa-check-circle intercom-check"
                ></i>
                <i
                    title="Non connecté"
                    v-else
                    class="fas fa-times-circle intercom-uncheck"
                ></i>
            </div>
            <div class="details" @click="gotoAccess(access.id, access.kibolt)">
                <h1>{{ access.nom }}</h1>
            </div>
            <div
                class="options"
                v-if="checkRightEvent || checkRightCode || checkRightBadge"
            >
                <button
                    class="options-button dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <i class="fas fa-ellipsis-v"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="options-button">
                    <a
                        @click="goToV1View('data/events/evts/id=' + access.id)"
                        class="dropdown-item"
                        v-if="checkRightEvent"
                    >
                        <i class="fas fa-calendar-day"></i>
                        {{ $t("accessCard.events") }}
                    </a>
                    <a
                        v-if="checkRightCode"
                        @click="goToV1View('data/codes/codes/id=' + access.id)"
                        class="dropdown-item"
                    >
                        <i class="fas fa-keyboard"></i>
                        {{
                            $t("accessCard.codes", {
                                count: access.codes_count,
                            })
                        }}
                    </a>
                    <a
                        v-if="checkRightBadge"
                        @click="
                            goToV1View(
                                'data/search_all/srch_all/fromacces=' +
                                    access.id
                            )
                        "
                        class="dropdown-item"
                    >
                        <i class="fas fa-key"></i>
                        {{
                            $t("accessCard.badges", {
                                count: access.cles_count,
                            })
                        }}
                    </a>
                    <a
                        v-if="checkRightOpenDoor"
                        @click="callOpenDoor(access.id)"
                        class="dropdown-item"
                    >
                        <i class="fas fa-door-open"></i>
                        {{ $t("accessCard.opendoor") }}
                    </a>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import v1mixin from "@/mixins/v1.js"
import { stairwellType } from "@/enums"
import { useToast } from "vue-toastification"
import httpIntratone from "@/services/httpIntratone.js"
import { openAccess } from "@/services/intratone/access.js"

const toast = useToast()

export default {
    name: "AccesCard",
    mixins: [v1mixin],
    props: {
        access: {
            type: Object,
            default: function () {
                return null
            },
        },
        isCreator: {
            type: Boolean,
            default: false,
        },
        isConnectit: {
            type: Boolean,
            default: false,
        },
        idEntity: {
            type: String,
            default: "",
        },
        typeEntity: {
            type: String,
            default: "",
        },
        idParent: {
            type: String,
            default: "",
        },
        entityParent: {
            type: String,
            default: "",
        },
        display: {
            type: String,
            default: "card",
        },
    },
    watch: {
        display: {
            immediate: true,
            handler() {
                this.displayMode = this.display
            },
        },
    },
    data() {
        return {
            imageBaseURL: process.env.VUE_APP_IMAGES_URL,
            stairwellType,
            createType: this.entityParent,
            idType: this.idParent,
        }
    },
    computed: {
        accessName() {
            const accessLength = 80
            let accessName = this.access.nom
            if (this.access.nom.length > accessLength) {
                accessName = this.access.nom.substring(0, accessLength) + " ..."
            }

            return accessName
        },
        getCentralId() {
            return this.access.centrale_id
                ? parseInt(this.access.centrale_id)
                : 0
        },
        checkRightEvent() {
            return (
                (this.access.clav === "1" ||
                    this.access.hf === "1" ||
                    this.access.lect === "1" ||
                    this.access.plat === "1" ||
                    this.access.pea !== "0" ||
                    this.access.pe !== "0" ||
                    this.access.idmatos !== "0" || // accès sans matériel
                    this.access.idmatos !== "14") && // carte HCR'
                this.getCentralId > 0 &&
                this.access.rw === "0" &&
                this.access.kibolt === "0" &&
                this.access.idmatos !== "13" && // centrale HIL
                (this.access.ecran === "" || this.access.ecran === "0") &&
                this.checkRight("G_EVENT")
            )
        },
        checkRightCode() {
            return (
                (this.access.clav === "1" ||
                    this.access.plat === "1" ||
                    this.access.pea !== "0" ||
                    this.access.pe !== "0") &&
                this.getCentralId > 0 &&
                this.access.rw === "0" &&
                this.access.kibolt === "0" &&
                (this.checkRight("G_CODE_CLAV") ||
                    this.checkRight("G_CODE_TEL"))
            )
        },
        checkRightBadge() {
            return (
                this.access.hf === "1" ||
                this.access.lect === "1" ||
                this.access.kibolt === "1" ||
                this.access.plat === "1" ||
                this.access.pe !== "0" ||
                this.access.pea !== "0" ||
                this.access.idmatos === "0" || // accès sans matériel
                this.access.idmatos === "4" || // clavier hcc12
                this.access.idmatos === "14" || // carte HCR
                this.access.idmatos === "32" // haussmann sans lecteur
            )
        },
        checkRightOpenDoor() {
            return (
                this.access.rw === "0" &&
                this.access.lite === "0" &&
                this.access.kibolt === "0" &&
                this.getCentralId > 0 &&
                (this.access.ecran === "" || this.access.ecran === "0") &&
                this.checkPrivilege("OPEN_DOOR")
            )
        },
    },
    methods: {
        async createAction() {
            if (this.typeEntity !== "") {
                if (this.typeEntity === stairwellType.BUILDING) {
                    this.createType = "building"
                } else if (this.typeEntity === stairwellType.STAIRWELL) {
                    this.createType = "stairwell"
                }
                this.idType = this.idEntity
            }
            let v1Call = null
            switch (this.createType) {
                case "apartment":
                    this.openV1Modal("data/appart/appart_add", "Act_Zone")
                    break
                case "stairwell":
                    if (this.idType) {
                        v1Call = await httpIntratone.post(
                            "data/cage/cage.php",
                            `id=${this.idType}&SID=${this.$store.getters["auth/getIntratoneDev2SID"]}`
                        )
                    }
                    this.openV1Modal("data/cage/action/cage_add", "Act_Zone")
                    break
                case "building":
                    if (this.idType) {
                        v1Call = await httpIntratone.post(
                            "data/batiment.php",
                            `id=${this.idType}&type=BATIMENT&SID=${this.$store.getters["auth/getIntratoneDev2SID"]}`
                        )
                    }
                    this.openV1Modal("data/batiment_add", "Act_Zone")
                    break
                case "residence":
                    this.openV1Modal("data/residence/add", "Act_Zone")
                    break
            }
        },

        gotoConnectit() {
            this.goToV1View("data/lora/res_devices/reset=1")
        },

        gotoAccess(id, kibolt) {
            const redirectionInfo = `&from=${this.entityParent}&fromId=${this.idParent}`
            if (kibolt === "1") {
                this.$router.push({
                    path: `/${this.entityParent}/${this.idParent}/cylinder/${id}`,
                })
                // this.goToV1View(
                //     "data/kibolt/cylinder/acc_kibolt/id=" + id + redirectionInfo
                // )
            } else {
                this.goToV1View("data/acces/load/id=" + id + redirectionInfo)
            }
        },

        async callOpenDoor(id) {
            let openAccessResponse = await openAccess(id)

            if (openAccessResponse === null) {
                throw new Error()
            }
            if (openAccessResponse.state === "error") {
                if (openAccessResponse.code === "API_RESTRICTED") {
                    this.$router.push({
                        path: "/error/401",
                    })
                } else {
                    this.$router.push({
                        path: "/error/404",
                    })
                }
                return
            } else {
                toast.success(this.$t("alerts.access.opendoor"), {
                    icon: "fas fa-door-open",
                })
            }
        },
    },
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.access-list-individual {
    width: 100%;
    display: flex;
    border: none;
    transition: all ease-in-out 150ms;
    border-bottom: 1px solid $grey-light;
    cursor: pointer;

    &:hover {
        background-color: $grey-light;
        transition: all ease-in-out 150ms;

        .options {
            button {
                background-color: $grey-light;
            }
        }
    }

    .list-creator {
        display: flex;
        gap: 1em;
        width: 100%;
        height: 100%;
        padding: 1em 3em;
        cursor: pointer;
        transition: background-color 200ms;
        border: none;

        div {
            display: flex;
            align-items: center;
            gap: 1em;

            i {
                color: $orange-neutral;
                font-size: $big;
                margin: auto;
            }

            h1 {
                margin: 0 !important;
                font-size: $normal;
                font-family: $font_avenir_heavy;
                color: $orange-neutral;
            }
        }

        &:hover {
            background-color: $orange-residence-dark;
        }
    }

    .access-list-connectit {
        border: none;
        display: flex;
        justify-content: center;
        grid-column: span 3 / span 3;
        padding: 0.5em 2em;
        transition: all 200ms;
        width: 100%;

        img {
            max-height: 40px;
        }
    }

    .access-info {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 10fr 1fr;
        gap: 1em;
        padding: 0.5em 0;
        border-radius: 10px;
        transition: background-color 200ms;

        .access-list-image {
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 40px;
            }

            i {
                align-self: start;
                font-size: $big;
            }

            .intercom-check {
                color: $green-check;
            }

            .intercom-uncheck {
                color: $red-uncheck;
            }
        }

        .access-list-name {
            font-size: $normal;
        }
    }

    .access-list-name {
        align-self: center;
        width: 100%;
        font-size: $small;
        font-family: "Avenir Heavy";
    }

    .options {
        width: 100%;
        padding-right: 1em;
        display: flex;
        align-items: center;
        justify-content: end;
        justify-items: end;

        button {
            border-radius: 30px;
            border: none;
            padding: 6px 6px 5px 6px;
            transition: background-color 200ms;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;

            i {
                font-size: $big;
                color: $blue-neutral;
            }
        }

        button:hover {
            background-color: $grey-light;
        }

        .dropdown-item {
            font-size: $small;
            transition: background-color 200ms, color 200ms, padding 200ms;
            color: $blue-neutral;
            font-family: "Avenir Heavy";

            i {
                font-size: $big;
                margin-right: 15px;
            }
        }

        .dropdown-item:hover {
            cursor: pointer;
            color: $orange-neutral;
            background-color: $grey-lighter;
            padding-left: 30px;
        }

        .dropdown-toggle:after {
            display: none;
        }
    }
}

.access-card-individual {
    display: flex;
    align-items: center;
    gap: 1em;
    min-height: 55px;
    max-height: max-content;
    border-radius: 15px;
    transition: all 200ms;

    @media all and (max-width: 1024px) and (min-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        width: 100%;
    }

    .access-card-content {
        padding: 0.4em 0.8em;
        border-radius: 0.4em;
        display: flex;
        transition: all 150ms;
        width: 100%;
        border: 1px solid $grey-light;

        &:hover {
            cursor: pointer;
            background-color: $grey-light;
            transition: all 150ms;

            .options {
                button {
                    background-color: $grey-light;
                }
            }
        }
    }

    .visual {
        min-width: max-content;
        width: 9em;
        border-right: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
            max-height: 40px;
        }

        i {
            padding: 0px 0px 25px 0px;
            font-size: $big;
        }

        .intercom-check {
            color: $green-check;
        }

        .intercom-uncheck {
            color: $red-uncheck;
        }

        .material {
            max-width: 50px;
        }
    }

    .details {
        @media all and (min-width: 1024px) {
            width: 100%;
        }

        @media all and (max-width: 1024px) and (min-width: 768px) {
            width: 100%;
        }

        @media all and (max-width: 768px) {
            width: 100%;
        }

        padding: 0 8px 0 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        h1 {
            width: 100%;
            color: $blue-neutral;
            font-family: "Avenir Heavy";
            text-align: left;
            font-size: $small;
            margin: 0;
            line-height: 22px;
            transition: color 200ms;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
    }

    .options {
        width: max-content;
        padding-right: 1em;
        display: flex;
        align-items: center;
        justify-content: end;

        button {
            border-radius: 30px;
            border: none;
            padding: 6px 6px 5px 6px;
            transition: background-color 200ms;
            background-color: $white;

            i {
                font-size: $big;
                color: $blue-neutral;
            }
        }

        button:hover {
            background-color: $grey-dark;
        }

        .dropdown-item {
            font-size: $small;
            transition: background-color 200ms, color 200ms, padding 200ms;
            color: $blue-neutral;
            font-family: "Avenir Heavy";

            i {
                font-size: $big;
                margin-right: 15px;
            }
        }

        .dropdown-item:hover {
            cursor: pointer;
            color: $orange-neutral;
            background-color: $grey-lighter;
            padding-left: 30px;
        }

        .dropdown-toggle:after {
            display: none;
        }
    }

    .card-creator-wrapper {
        display: flex;
        justify-content: center;
        height: 100%;

        .card-creator {
            display: flex;
            gap: 1em;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 1em 3em;
            border-radius: 15px;
            cursor: pointer;
            background-color: $orange-neutral;
            transition: background-color 200ms;
            color: $white;
            border: none;

            &:hover {
                background-color: $orange-light;
                border: none;
                transition: background-color 200ms;
            }

            i {
                color: $white;
                font-size: $big;
            }

            h1 {
                font-size: $normal;
                font-family: $font_avenir_heavy;
                color: $white;
                margin: 0;
            }
        }
    }
}

.card-connectit {
    width: 100%;
    cursor: pointer;
    border: 1px solid $grey-light;
    transition: background-color 200ms;
    padding: 0.4em 0.8em;
    border-radius: 0.7em;

    .visual {
        width: 100%;
    }

    @media all and (max-width: 1024px) and (min-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 768px) {
        width: 100%;
    }

    &:hover {
        background-color: $grey-light;
        transition: background-color 200ms;
    }
}
</style>
