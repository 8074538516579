<template>
    <div class="main-container">
        <div class="code-container" v-if="!isLoading">
            <div class="code-title">
                <h1>{{ $t("codesList.title") }}</h1>
                <p class="codes-number">
                    {{
                        $t("codesList.count", {
                            count: codesNumber,
                        })
                    }}
                    <span v-if="codesNumber > 1">
                        ({{
                            $t("exportFobs.fobsCount", {
                                minCount: minCodesNumber,
                                maxCount: maxCodesNumber,
                            })
                        }})
                    </span>
                </p>
                <p></p>
            </div>
            <div class="code-container">
                <CodeByAccessList :data="access.list" />
            </div>
        </div>
        <div class="loader" v-if="isLoading">
            <Loader />
        </div>
        <div class="pagination" v-if="access._pages > 1">
            <Pagination
                :page="parseFloat(page)"
                :pagesCount="access._pages"
                @new-current-page="nextPage($event)"
            />
        </div>
    </div>
</template>

<script>
import CodeByAccessList from "@/components/entities/CodeByAccessList.vue"
import Pagination from "../../components/basic/Pagination.vue"
import Loader from "@/components/basic/Loader.vue"

import { getAccess } from "../../services/intratone/access"
export default {
    name: "CodeByAccessView",
    data() {
        return {
            access: {},
            page: 1,
            isLoading: true,
            codesNumber: 0,
            minCodesNumber: 0,
            maxCodesNumber: 0,
            limit: 11,
        }
    },
    components: {
        CodeByAccessList,
        Pagination,
        Loader,
    },
    props: {
        residenceId: {
            type: String,
            default: null,
            required: true,
        },
        residencePart: {
            type: String,
            default: null,
            required: true,
        },
    },
    mounted() {
        if (this.$route.params.page_num) {
            this.page = this.$route.params.page_num
        }
        this.getAccessList(this.page)
    },

    methods: {
        async getAccessList(page) {
            this.isLoading = true

            await getAccess({
                idres: this.residenceId,
                stat: "codes",
                page: page,
                for: "codes",
                limit: this.limit,
            }).then((response) => {
                this.access = response.data
                this.codesNumber = parseInt(response.data._count)

                this.determinateCodeRange(
                    this.codesNumber,
                    parseInt(response.data._pages),
                    page,
                    this.limit
                )
            })

            this.page = page
            this.isLoading = false
        },
        async nextPage(page) {
            await this.getAccessList(page)
            if (this.page != 1) {
                this.$router.push({
                    name: this.residencePart,
                    params: {
                        tab: "codes",
                        page_num: this.page,
                    },
                })
            } else {
                this.$router.push({
                    name: this.residencePart,
                    params: {
                        tab: "codes",
                    },
                })
            }
        },
        determinateCodeRange(total, pages, page, limit) {
            if (total === 0) {
                this.maxCodesNumber = 0
                this.minCodesNumber = 0
            } else {
                if (page === pages) {
                    this.maxCodesNumber = total
                    this.minCodesNumber = limit * pages - limit + 1
                } else {
                    this.maxCodesNumber = limit * page

                    if (page > 1) {
                        this.minCodesNumber = this.maxCodesNumber - limit + 1
                    } else {
                        this.minCodesNumber = 1
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/global.scss";
@import "@/assets/scss/details.scss";

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-top: 2em;
    .code-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2em;
        flex-grow: 1;
        overflow-x: auto;

        .code-title {
            display: flex;
            justify-content: start;
            align-items: start;
            flex-direction: column;
            gap: 1em;

            h1 {
                font-family: $font-avenir-black;
                margin: 0;
            }
            .codes-number {
                font-size: $big;
                margin: 0 !important;
            }
        }
    }

    .pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: end;
    }

    .loader {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        flex-grow: 1;
    }
}
</style>
